import React, { Fragment } from "react";

function Contact() {
  return (
    <Fragment>
      <h1>Welcome to Contact Page!</h1>
      <p>Contact page...</p>
    </Fragment>
  );
}

export default Contact;
